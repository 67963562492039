const parties: string[] = [
  'Bharatiya Janata Party',
  'Indian National Congress',
  'Bahujan samaj party',
  'Communist Party of India (Marxist)',
  'Independent',
  'Other',
]

export default parties
