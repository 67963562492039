export const castes: string[] = [
  'brahmin',
  'rajput',
  'interest',
  'mahajan',
  'blacksmith',
  'chieftain',
  'potter',
  'dumna',
  'sikh',
  'goldsmith',
  'merchant',
  'cool',
  'hali',
  'sippy',
  'muslim',
  'christian',
  'anglo-indian',
]
