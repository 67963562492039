const reasons: string[] = [
  'Strong opposition',
  'Caste factor',
  'Party worker',
  `Candidate's image`,
  'Party national leadership',
  'Local leadership',
  'Other',
]

export default reasons
