export const keyIssues = [
  'Education',
  'Cleanliness',
  'Medical Facilities',
  'Corruption',
  'Drinking Water',
  'Security',
  'Roads and Infrastructure',
  'Rising Prices',
  'Unemployment',
]

export const reasonsToVoteACandidate = [
  'candidate property',
  'party national leadership',
  'party regional leadership',
  'candidate performance',
  'nothing special people want change',
  'candidate caste',
  'candidate image',
]
