const assemblyAreas: string[] = [
  'square',
  'bharmour',
  'Chamba',
  'Dalhousie',
  'Bhatiyati',
  'Nurpur',
  'Indore',
  'Fatehpur',
  'jawali',
  'dehra',
  'Jaswan-Pragpur',
  'Volcano',
  'Jaisinghpur',
  'sullah',
  'Nagrota',
  'Kangra',
  'Shahpur',
  'Dharamsala',
  'Palampur',
  'baijanathi',
  'Lahaul and Spiti',
  'Manali',
  'Kullu',
  'benjari',
  'Annie',
  'Karsogo',
  'Sundernagar',
  'Dancing',
  'Seraji',
  'colored',
  'Jogindernagar',
  'Dharampur',
  'mandi',
  'balho',
  'Sarka Valley',
  'Bhoranji',
  'Sujanpur',
  'Hamirpur',
  'Barsari',
  'nadaun',
  'Chintpurni',
  'Gagret',
  'Harolic',
  'un',
  'kutlehad',
  'Jhanduta',
  'twisting',
  'Bilaspur',
  'Shri Naina Deviji',
  'archie',
  'Nalagarh',
  'doon',
  'Solon',
  'Kasauli',
  'back',
  'nahan',
  'Shri Renukaji',
  'Paonta Sahib',
  'stitching',
  'Chopal',
  'theog',
  'Kasumpi',
  'Shimla',
  'Shimla rural',
  'jubbal-kotkhai',
  'Rampur',
  'Rohru',
  'Kinnaur',
]

export default assemblyAreas
